import Clear from'./Clear.png';
import Clouds from'./Clouds.png';
import Fog from'./Fog.png'
import Rain from'./Rain.png'
import Snow from'./Snow.png';

export default{
    Clear,
    Clouds,
    Fog,
    Rain,
    Snow
}
