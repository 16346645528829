const appid = "9add691b6641cfa2a60059fc1f132c8f"

const searchCity = (city) => {
    return fetch(`https://api.openweathermap.org/geo/1.0/direct?q=${city}&limit=1&appid=${appid}`)
        .then(res => res.json())

} 

const getStatusOfCoordinates = ({lat, lon}) => {
    return fetch(`https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&lon=${lon}&units=metric&appid=${appid}`)
        .then(res => res.json()).then(res => res.daily)
}

export default {
    searchCity,
    getStatusOfCoordinates
}