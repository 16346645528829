import Assets from '../assets'
import '../styles/day.css';

export default ({status}) => {
    return <div className="day">
        <p>{new Date(status.dt*1000).toDateString().split(" ")[0]}</p>
        <img src={Assets[status.weather[0].main]} /> 

        <p className="temp">
            <span className="temp-day">{~~status.temp.day}°</span>
            <span className="temp-night">{~~status.temp.night}°</span>
        </p>
    </div>
}
