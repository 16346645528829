import React, {useEffect, useState} from 'react';
import WeatherService from '../services/weather';

export const Context = React.createContext({});

const WeatherContext = ({children}) => {
    const [city, setCity] = useState("İstanbul");
    const [result, setResult] = useState()
    const [weatherStatuses, setWeatherStatuses] = useState([]);

    useEffect(() => {
        if(Boolean(city) === false) { return; }

        WeatherService.searchCity(city).then(async res => {
            if(Array.isArray(res) === false || res.length === 0){
                setResult(null)
                setWeatherStatuses([])
            }else{
                setResult(res[0])
                console.log(res)
                const daily = await WeatherService.getStatusOfCoordinates(res[0]);
                setWeatherStatuses(daily.slice(0, -1));
            }
        })
    }, [city])

    return <Context.Provider value={{result, city, weatherStatuses, setCity}}>
        {children}
    </Context.Provider>
}
export default WeatherContext;