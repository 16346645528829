import { useState, useContext } from "react"
import { Context } from '../context/weather';
import '../styles/header.css'

export default () => {
    const { city, setCity, result } = useContext(Context);
    const [_city, _setCity] = useState(city);

    const handlePressEnter = (e) => {
        if(e.key === "Enter"){
            setCity(e.target.value)
            _setCity(e.target.value)
        }
    }


    return <div className="header">
        <input value={_city} onKeyDown={handlePressEnter} onChange={({ target: { value } }) => _setCity(value)} type="text" placeholder="Şehir girin" />
        <span>― {result ? `${result.name}/${result.country} [${result.lat}, ${result.lon}]`  : "Not found"}</span>
    </div>
}