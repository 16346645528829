import WeatherContext from './context/weather';
import Weather from './components/weather';
import Header from './components/header';

function App() {
  return <WeatherContext>
    <Header />
    <Weather />
  </WeatherContext>
  
  }

export default App;
