import { useContext } from 'react';
import Day from './day';
import { Context } from '../context/weather';
import '../styles/weather.css'

export default () => {
    const {weatherStatuses} = useContext(Context);

    return <div className="weather">
        {weatherStatuses.map((status, key) =>
            <Day key={key} status={status} />
        )} 
    </div>
}